<template>
  <div>
    <b-card>
      <template #header>
        <card-header
          title="Legale Rappresentante"
          :button="{
              content: editButtonText,
              variant: 'info',
              classes: schedaSectionButtonClasses,
            }"
          :button-action="toggleEditMode"
        />
      </template>

      <b-form @submit.prevent="submitForm" class="form-inputs--mobile--lg">
        <b-form-row>
          <b-col class="mt-3 mt-md-0" md="12">
            <b-form-row>
              <b-col sm="6" lg="4">
                <field-text
                  :read-only="isDisabled"
                  fieldId="personInfo.name"
                  placeholder="Nome"
                  title="Nome"
                  :state="validateState($v.formLegalData.personInfo.name)"
                  v-model="$v.formLegalData.personInfo.name.$model"
                />
              </b-col>
              <b-col sm="6" lg="4">
                <field-text
                  :read-only="isDisabled"
                  fieldId="surname"
                  placeholder="Cognome"
                  title="Cognome"
                  :state="validateState($v.formLegalData.personInfo.surname)"
                  v-model="$v.formLegalData.personInfo.surname.$model"
                />
              </b-col>
              <b-col sm="6" lg="4">
                <field-select
                  :read-only="isDisabled"
                  title="Sesso"
                  :options="genderOptions"
                  :state="validateState($v.formLegalData.anagraficalInfo.gender)"
                  v-model="$v.formLegalData.anagraficalInfo.gender.$model"
                ></field-select>
              </b-col>
              <b-col sm="6" lg="4">
                <field-text
                  :read-only="isDisabled"
                  fieldId="taxCode"
                  placeholder="Codice Fiscale"
                  title="Codice Fiscale"
                  :state="validateState($v.formLegalData.anagraficalInfo.taxCode)"
                  v-model="$v.formLegalData.anagraficalInfo.taxCode.$model"
                  :errorMessage="decodeError($v.formLegalData, 'anagraficalInfo.taxCode')"
                />
              </b-col>
              <b-col sm="6" lg="4">
                <field-text
                  :read-only="isDisabled"
                  fieldId="email"
                  placeholder="Email"
                  title="Email"
                  :state="validateState($v.formLegalData.personInfo.email)"
                  v-model="$v.formLegalData.personInfo.email.$model"
                  :errorMessage="decodeError($v.formLegalData, 'personInfo.email', this.formLegalData)"
                />
              </b-col>
              <b-col sm="6" lg="4">
                <field-nations
                  :read-only="isDisabled"
                  title="Nazionalità"
                  :state="validateState($v.formLegalData.anagraficalInfo.nationalityCountryCode)"
                  v-model="$v.formLegalData.anagraficalInfo.nationalityCountryCode.$model"
                ></field-nations>
              </b-col>
              <b-col sm="6">
                <field-calendar :read-only="isDisabled"
                                title="Data di nascita"
                                :state="validateState($v.formLegalData.anagraficalInfo.birthDate)"
                                v-model="$v.formLegalData.anagraficalInfo.birthDate.$model"
                                :errorMessage="decodeError($v.formLegalData, 'anagraficalInfo.birthDate')"
                ></field-calendar>
              </b-col>
              <b-col sm="6">
                <field-text
                  :read-only="isDisabled"
                  fieldId="mobile"
                  placeholder="Cellulare"
                  title="Cellulare"
                  :state="validateState($v.formLegalData.personInfo.mobilePhone)"
                  v-model="$v.formLegalData.personInfo.mobilePhone.$model"
                />
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col md="12">
                <field-city
                  :read-only="isDisabled"
                  title="Luogo di nascita"
                  field-id="birthCity"
                  v-model="$v.formLegalData.anagraficalInfo.birthCity.$model"
                  :state="fieldStateBirthCity"
                />
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col md="12">
                <field-address
                  :read-only="isDisabled"
                  title="Indirizzo di residenza"
                  field-id="residencialAddress"
                  v-model="$v.formLegalData.residencialAddress.$model"
                  :state="fieldState"
                />
              </b-col>
            </b-form-row>
            <div>
              <label class="col-form-label col-form-label--accent">Documenti</label>
              <div>
                <b-form-row>
                  <b-col cols="12">
                    <document-upload
                      v-if="hasDocuments"
                      :enable-upload="!isDisabled"
                      :read-only="isDisabled"
                      :on-click="onUploadDoc"
                      :document="currentIdentityDoc"
                      entity-type="PERSON"
                      slim
                    />
                  </b-col>
                  <b-col md="6">
                    <field-text
                      :read-only="isDisabled"
                      fieldId="docNumber"
                      placeholder="Numero documento"
                      title="Numero documento"
                      :state="validateState($v.formLegalData.docIdentity.docNumber)"
                      v-model="$v.formLegalData.docIdentity.docNumber.$model"
                    />
                  </b-col>
                  <b-col md="6">
                    <field-calendar
                      :read-only="isDisabled"
                      title="Data di scadenza"
                      v-model="$v.formLegalData.docIdentity.expireDate.$model"
                      :state="validateState($v.formLegalData.docIdentity.expireDate, ['checkExpired'])"
                      :errorMessage="decodeError($v.formLegalData, 'docIdentity.expireDate')"
                    ></field-calendar>
                  </b-col>
                </b-form-row>
              </div>
            </div>
            <div class="p-2 bg-gray-200 text-right" v-if="!isDisabled">
              <button-loader
                buttonStyle="success"
                buttonClass="text-uppercase btn-success"
                content="Salva"
                :disabled="$v.formLegalData.$invalid"
                :loading="submitted"
              />
            </div>
          </b-col>
        </b-form-row>
      </b-form>
    </b-card>

    <modal-document-upload
      v-if="hasDocuments"
      id="legalDocs"
      entity-type="PERSON"
      :entity-id="formLegalData.personId"
      :document-name="documentToUpload.documentName"
      :document="documentToUpload.document"
      :on-success="updateDoc"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import lodash from 'lodash';
import email from 'vuelidate/lib/validators/email';
import {
  checkAge, checkExpired,
  checkTaxCode, convertSetFieldName, decodeErrorMessage,
  decodeFormFieldError,
  fieldStateAddress,
  fieldStateCity,
  isNotEmpty, isPresent,
  omitNull, removeProp, serverError,
  VALIDATE_ADDRESS,
  VALIDATE_CITY,
  validateFormFieldState,
} from '@/utils/validators';

const ButtonLoader = () => import('@/components/ButtonLoader.vue');
const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');
const FieldNations = () => import('@/components/fields/FieldNations.vue');
const FieldCalendar = () => import('@/components/fields/FieldCalendar.vue');
const FieldText = () => import('@/components/fields/FieldText.vue');
const FieldSelect = () => import('@/components/fields/FieldSelect.vue');
const FieldAddress = () => import('@/components/fields/FieldAddress.vue');
const FieldCity = () => import('@/components/fields/FieldCity.vue');
const ModalDocumentUpload = () => import('@/components/documents/ModalDocumentUpload.vue');
const DocumentUpload = () => import('@/components/documents/DocumentUpload.vue');

export default {
  name: 'FormCompanyLegalData',
  components: {
    DocumentUpload,
    ModalDocumentUpload,
    FieldCity,
    FieldAddress,
    FieldSelect,
    FieldText,
    ButtonLoader,
    FieldNations,
    FieldCalendar,
    CardHeader,
  },
  props: {
    hasCompany: Boolean,
    changeCompanyTab: Function,
    readOnly: Boolean,
  },
  mounted() {
    // console.log('legal mounted');
    this.populateForm();
  },
  data() {
    return {
      genderOptions: [
        // { key: null, value: 'Seleziona' },
        { key: 'MALE', value: 'Maschio' },
        { key: 'FEMALE', value: 'Femmina' },
      ],
      formLegalData: null,
      submitted: false,
      showCompany: false,
      documentToUpload: {
        document: null,
        documentName: null,
      },
      identityDoc: null,
      editMode: false,
      clientValidation: {
        formLegalData: {
          personInfo: {
            name: {
              required,
            },
            surname: {
              required,
            },
            mobilePhone: {
              required,
            },
            email: {
              required,
              email,
            },
          },
          anagraficalInfo: {
            gender: {
              required,
            },
            taxCode: {
              required,
              checkTaxCode,
            },
            birthCity: {
              ...VALIDATE_CITY,
            },
            birthDate: {
              required,
              checkAge,
            },
            nationalityCountryCode: {
              required,
            },
          },
          residencialAddress: {
            ...VALIDATE_ADDRESS,
          },
          docIdentity: {
            docNumber: {
              required,
            },
            expireDate: {
              required,
              checkExpired,
            },
          },
        },
      },
      serverValidation: {},
    };
  },
  computed: {
    rules() {
      // When the serverValidation property is set this computed property
      // fires and merges the client and server validation
      return lodash.merge({}, this.serverValidation, this.clientValidation);
    },
    fieldState() {
      return fieldStateAddress(this.$v.formLegalData.residencialAddress);
    },
    fieldStateBirthCity() {
      return fieldStateCity(this.$v.formLegalData.anagraficalInfo.birthCity);
    },
    hasDocuments() {
      const legal = this.$store.getters['subject/currentCompanyLegal'];
      return this.$store.getters['documents/hasDocumentList']('person', legal.personId);
    },
    currentIdentityDoc() {
      if (this.identityDoc === null && this.hasDocuments) {
        this.setIdentityDoc();
      }
      return this.identityDoc;
    },
    buttonText() {
      return this.showCompany ? 'Nascondi sezione società' : 'Inserisci i dati di una società';
    },
    isDisabled() {
      return (this.readOnly || !this.editMode);
    },
    schedaSectionButtonClasses() {
      if (this.readOnly) return 'd-none';
      return 'btn-xs ms-2';
    },
    editButtonText() {
      if (this.editMode) {
        return 'Annulla modifiche';
      }
      return 'Compila';
    },
  },
  validations() {
    return this.rules;
  },
  methods: {
    validateState: validateFormFieldState,
    decodeError: decodeFormFieldError,
    openModalUpload(doc, documentName) {
      this.documentToUpload = {
        document: doc,
        documentName,
      };
      this.$bvModal.show('legalDocs');
    },
    setIdentityDoc() {
      const legal = this.$store.getters['subject/currentCompanyLegal'];
      this.identityDoc = this.$store.getters['documents/getDocument']('person', legal.personId, 'LEGAL', 'IDENTITY');
      if (isNotEmpty(this.identityDoc) && isNotEmpty(this.identityDoc.additionalInfos)) {
        this.$set(this.formLegalData.docIdentity, 'docNumber', this.identityDoc.additionalInfos.docNumber);
        this.$set(this.formLegalData.docIdentity, 'expireDate', this.identityDoc.additionalInfos.expireDate);
      }
    },
    toggleEditMode() {
      this.editMode = !this.editMode;
      if (!this.editMode) {
        this.$v.$reset();
        if (!this.submitted) {
          this.populateForm();
        }
      } else {
        this.$v.$touch();
      }
    },
    populateForm() {
      const legal = this.$store.getters['subject/currentCompanyLegal'];
      // const docIdentity = this.currentIdentityDoc;
      const deepcloned = isNotEmpty(legal) ? lodash.cloneDeep(legal) : null;
      console.log('legal populateForm');
      const empty = {
        personInfo: {
          name: null,
          surname: null,
          mobilePhone: null,
          email: null,
        },
        anagraficalInfo: {
          gender: null,
          taxCode: null,
          birthCity: {
            type: 'GENERIC',
            countryCode: null,
            city: null,
            province: null,
          },
          birthDate: null,
          nationalityCountryCode: null,
        },
        residencialAddress: {
          type: 'RESIDENCIAL',
          countryCode: null,
          streetName: null,
          zip: null,
          city: null,
          province: null,
        },
        docIdentity: {
          docNumber: null,
          expireDate: null,
        },
      };
      if (isNotEmpty(deepcloned)) {
        this.formLegalData = { ...empty, ...omitNull(deepcloned) };
        // if (isNotEmpty(docIdentity) && isNotEmpty(docIdentity.additionalInfos)) {
        //   this.$set(this.formLegalData.docIdentity, 'docNumber', docIdentity.additionalInfos.docNumber);
        //   this.$set(this.formLegalData.docIdentity, 'expireDate', docIdentity.additionalInfos.expireDate);
        //   // this.formLegalData.docIdentity.docNumber = docIdentity.additionalInfos.docNumber;
        //   // this.formLegalData.docIdentity.expireDate = docIdentity.additionalInfos.expireDate;
        // }
      } else {
        this.formLegalData = { ...empty };
      }
      this.$v.$touch();
    },
    submitForm() {
      if (this.isDisabled) return;
      this.clearServerErrors();
      if (this.$v.$invalid) return;

      console.log('submitForm', this.formLegalData);
      this.submitted = true;
      const company = this.$store.getters['subject/currentCompany'];
      const { docIdentity, ...person } = this.formLegalData;
      this.$store.dispatch('subject/savePerson', {
        person,
        company,
      }).then(
        () => {
          const doc = this.identityDoc;
          const toUpdate = { ...doc, additionalInfos: { ...docIdentity } };
          this.$store.dispatch('documents/updateDocument', {
            document: toUpdate,
            entityClass: 'PERSON',
            entityId: person.personId,
          }).then((docUpdated) => {
            this.updateDoc(docUpdated);
            this.toggleEditMode();
            this.submitted = false;
          },
          (responseError) => {
            this.processError(responseError.errors);
            this.submitted = false;
          });
        },
        (responseError) => {
          console.log('save failed');
          this.processError(responseError.errors);
          this.submitted = false;
        },
      );
    },
    processError(errors) {
      if (!errors) {
        this.submitted = false;
        return;
      }
      console.log('errors', errors);
      const serverMessages = {
        serverErrors: {
        },
      };
      this.serverValidation = {
        formLegalData: {
        },
      };

      errors.forEach((error) => {
        const { field, code } = error;
        if (isPresent(field)) {
          convertSetFieldName(serverMessages.serverErrors, field, decodeErrorMessage(field, code));
          convertSetFieldName(this.serverValidation.formLegalData, field, {
            serverError: serverError(field, false, this.formLegalData),
          });
        }
      });

      // Merge the server errors into the data. This doesn't yet cause
      // any validation errors but does make the messages (and therefore state)
      // accessible to the template.
      lodash.merge(this.formLegalData, serverMessages);

      this.submitted = false;
    },
    clearServerErrors() {
      // Clearing the server validation removes the rules and
      // therefore server errors no longer contribute to validation state.
      this.serverValidation = {};
      // Clearing the server errors from the data isn't necessary either
      // but again makes for good housekeeping and we'd need to do this
      // if submitting this data to the server.
      removeProp(this.formData, 'serverErrors');
    },
    clearServerError(model, fieldName) {
      // When an input control is modified we see if there are any
      // server errors and clear them. This causes the serverError validation
      // rule to trigger and clear it's error state.
      // eslint-disable-next-line no-prototype-builtins
      if (model.hasOwnProperty('serverErrors')) {
        // eslint-disable-next-line no-prototype-builtins
        if (model.serverErrors.hasOwnProperty(fieldName)) {
          // eslint-disable-next-line no-param-reassign
          delete model.serverErrors[fieldName];
        }
      }
    },
    updateDoc(docUpdated) {
      this.identityDoc = docUpdated;
    },
    onUploadDoc() {
      this.openModalUpload(this.currentIdentityDoc, 'Documento d\'identità');
    },
  },
};
</script>
